/* SearchResults.css */

.search-results {
    margin: 20px auto; /* Center the content */
    padding: 20px;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
  }
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px; /* Spacing below the heading */
  }
  
  .query {
    font-weight: bold; /* Highlight the query text */
    color: #007bff; /* Blue color for emphasis */
  }
  
  .product-list {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
  }
  
  .product-item {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0; /* Separator line */
    transition: background-color 0.3s; /* Smooth hover effect */
  }
  
  .product-item:hover {
    background-color: #f1f1f1; /* Highlight on hover */
  }
  
  .price {
    font-weight: bold; /* Emphasize price */
    color: #3F7CAC; /* Use a color consistent with your theme */
  }
  
  p {
    color: #666; /* Lighter text color for less emphasis */
  }
  