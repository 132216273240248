.footer {
  background: linear-gradient(rgba(253, 253, 253, 0.641), rgba(25, 162, 196, 0.603)), 
              url('../assets/footer-bg.jpg') no-repeat center center;
  background-size: cover;
  padding: 20px;
  color: white;
  text-align: left;
  position: relative;
  z-index: 1;
}

.footer-column {
  flex: 1;
  padding: 20px;
  background: rgba(255, 255, 255, 0.271); /* Transparent white background */
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  backdrop-filter: blur(10px); /* Blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Slight border for glassmorphic effect */
  margin: 0 10px;
}

.footer-container {
  display: flex;
  gap: 20px; /* Spacing between columns */
  padding: 40px 0;
}

.footer h3 {
  margin-bottom: 15px;
  color: rgb(0, 0, 0); /* Heading color */
}

.footer-column p, .footer-column ul, .footer-column li, .footer-column a {
  color: rgb(215, 80, 13); /* Text color */
  text-decoration: none;
}

.contact-info li {
  margin-bottom: 10px;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  color: white;
}

.contact-info {
  list-style: none;
  padding: 0;
}

.footer-menu {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-menu li {
  margin: 0 10px;
}

.footer-menu li a {
  color: white;
  text-decoration: none;
}

.footer-menu li a:hover {
  text-decoration: underline;
}

.logo-image {
  width: 200px;
  height: auto;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .footer {
    transform: scale(0.85); /* Scale down the footer slightly */
  }

  .footer-container {
    flex-direction: column; /* Stack columns vertically */
    gap: 10px;
  }

  .footer-column {
    margin: 10px 0; /* Add some margin between the stacked columns */
  }
}

@media (max-width: 480px) {
  .footer {
    transform: scale(0.7); /* Further scale down for smaller screens */
  }

  .logo-image {
    width: 120px; /* Reduce logo size */
  }
}
