/* General Styling */
.home-page {
  font-family: Arial, sans-serif;
  padding-top: 50px; /* Adjusted to account for navbar height and menu */
  background-color: #fcfcfc;
  scroll-behavior: smooth; /* Smooth scrolling for the entire page */
}

.home-header {
  text-align: center;
  padding: 20px;
  color: #ffffff;
}

.carousel-section{
  padding-bottom: 30px;
}


/* Adjust Product Card Styling */
.product-itemm {
  border: 1px solid #1d50eab0;
  border-radius: 5px;
  text-align: center;
  position: relative; /* For the View Details button positioning */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0; /* Removed the margin for consistent spacing with grid-gap */
}




/* Apply hover effect when hovering on the entire product-item */
.product-itemm:hover .product-image {
  transform: scale(1.1);

}

/* Product details occupy the bottom half */
.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%; /* Occupy the bottom half of the card */
 
}

/* Product Name Styling */
.product-name {
  font-size: 14px; /* Adjust as needed */
  font-weight: bold;
  margin: 2px 0;
  display: -webkit-box; /* Enables flexbox for the text */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical; /* Ensures vertical layout */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow */
}




/* Style for was price */
.was-pricee {
  font-size: 12px; 
  color: rgb(255, 0, 0);
  text-decoration: line-through; 
  text-align: center;
  padding-left: 8px;
  margin: 2px 0;
}

/* Add margin if necessary */
.product-pricee {
  padding-left: 10px;
  color: #0056b3;
  font-size: 18px;
  padding-right: 8px;
  margin: 2px 0;
}

/* Limit the product description */
.product-description {
  font-size: 12px;
  margin: 2px 0;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Ensure View Details button stays at the bottom */
.view-details-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: auto; /* Ensure it's at the bottom */
}

.view-details-button:hover {
  background-color: #fcfcfc;
  border: #007bff;
  color: #007bff;
}

/* Product Category Styling */
.product-category {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px; /* Set a max-width to control the overall width */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

/* Scroll buttons styling */
.scroll-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.scroll-button.left {
  left: 10px;
  color: #007bff;
}

.scroll-button.right {
  right: 10px;
  color: #007bff;
}

.scroll-button:hover {
  background-color: #0056b3;
}

/* Product List Container Styling for Horizontal Scroll */
.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Dynamic columns for responsiveness */
  grid-gap: 20px; /* Space between products */
  padding: 20px 0; /* Keep the existing padding */
}

.product-list::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

/* Category Header Styling */
.product-category h2 {
  margin: 0;
  padding: 10px;
  color: #3F7CAC;
  font-size: 24px;
  background-color: #fcfcfc;
}

/* Specific Category Background */
.featured-products,
.latest-products,
.most-selling,
.on-offers,
.laptops,
.spare-parts,
.repair-services {
  background-color: #ffffff;
}

.shop-now-button:hover {
  background-color: #0056b3;
}

.add-to-cart-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #b9d6e1a1;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.add-to-cart-icon:hover {
  transform: scale(1.1);
}

.product-itemm:hover .add-to-cart-icon {
  display: flex;
  opacity: 1;
}

.add-to-cart-icon::after {
  content: "cart";
  position: absolute;
  top: -30px;
  background-color: #23297a;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.add-to-cart-icon:hover::after {
  opacity: 1;
}



/* Responsive Design */
@media (max-width: 768px) {
  .product-item {
    flex: 0 0 48%; /* Two items per row on tablets */
    max-width: 48%;
  }

  .product-list {
    gap: 5px;
    padding: 5px 0;
  }

  .product-category {
    padding: 0 20px; /* Reduce padding for smaller screens */
  }

  .product-name,
  .product-price,
  .product-description {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .product-item {
    flex: 0 0 100%; /* One item per row on mobile */
    max-width: 100%;
  }

  .product-list {
    gap: 5px;
    padding: 5px 0;
  }

  .product-category {
    padding: 0 10px; /* Further reduce padding on mobile */
  }

  .product-name,
  .product-price,
  .product-description {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .product-detail .image-info-container {
    flex-direction: column; /* Stack images and product info vertically on smaller screens */
  }

  .product-detail .product-info {
    max-width: 100%;
  }

  .related-products-slider {
    display: flex;
    overflow-x: scroll; /* Enable horizontal scroll on smaller devices */
  }

  .related-product-card {
    flex: 0 0 150px; /* Make each related product take up a smaller width on mobile */
  }

  .add-to-cart-button {
    width: 100%; /* Full-width button for mobile */
  }
}




/* Skeleton loader styles */
.skeleton-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  margin: 20px 0;
  padding: 10px;
  align-items: center;
  margin-left: 300px;
  margin-right: 300px;
}

.skeleton-card {
  width:300px;
  height: 340px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f0f0f0;
  overflow: hidden;
  animation: fadeOut 10s forwards ease-in-out; /* Skeleton fades out after 10 seconds */
}

.skeleton-card .skeleton-image {
  width: 100%;
  height: 180px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f7f7f7 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear, scaleUp 1.5s infinite alternate;
}

.skeleton-card .skeleton-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-text {
  height: 15px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f7f7f7 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear, scaleUp 1.5s infinite alternate;
}

.skeleton-text.short {
  width: 50%;
}

.skeleton-text.medium {
  width: 80%;
}

.skeleton-text.long {
  width: 100%;
}

/* Shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Scale up for slight zoom-in effect */
@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
  }
}

/* Fade out after 10 seconds */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
