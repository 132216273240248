.cart-page {
  font-family: 'Manrope', sans-serif;
  background: #eee;
  margin-top: 30px;
}
.cart-page h1{
  text-align: center;
  color: #000000;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.cart-item-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin-left: 300px;   
  margin-right: 300px; 
  height: 150px;
}

.cart-item-details {
  flex: 2;
  padding-right: 15px;
  padding-left: 20px;  /* Added padding to left */
}

.cart-item-name {
  font-weight: bold;
}

.cart-item-description {
  color: #b50909;
  margin-top: 10px; /* Adds space between name and description */
  margin-left: 10px; /* Added margin to left */
  margin-right: 10px; /* Added margin to right */
}

.cart-item-quantity {
  display: flex;
  align-items: left;
}

.cart-item-quantity {
	display: flex;
	flex-direction: row; /* Keep buttons and quantity in a row */
	align-items: center; /* Align items vertically in the center */
	gap: 10px; /* Add space between buttons and quantity */
  }
  
  .cart-item-quantity button {
	background-color: #23297a83; /* Consistent button background */
	color: #fff; /* White text for contrast */
	border: none;
	border-radius: 4px; /* Rounded corners for a modern look */
	width: 36px; /* Fixed width for consistency */
	height: 36px; /* Fixed height for consistency */
	font-size: 1rem; /* Legible font size */
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .cart-item-quantity button:hover {
	background-color: #1e255e; /* Slightly darker hover state */
  }
  
  .cart-item-quantity span {
	font-size: 1.2rem; /* Make the quantity text slightly larger */
	font-weight: bold; /* Highlight the quantity value */
	min-width: 24px; /* Ensure consistent width */
	text-align: center; /* Center-align the quantity */
  }
  

.cart-item-price {
  font-weight: bold;
  padding: 0 20px;
}

.cart-item-image {
  flex: 1;
  text-align: center;  /* Center the image */
}

.cart-item-image img {
  max-width: 100px;
  border-radius: 5px;
}

.cart-item-price p {
  margin: 0;
}



.remove-item-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px;
}

.remove-item-button:hover {
  background-color: #ff1a1a;
}


.cart-total {
  margin-left: 500px;
  margin-right: 500px;
  margin-bottom: 30px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 8px;
  height: 50px;
}


.empty-cart {
  text-align: center;
  padding: 20px;
  width: 500px;
  height: 250px;
  background-color: #82dbed32;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
  margin-left: auto;   
  margin-right: auto; 
  box-shadow: inset;  
}

.empty-cart h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.empty-cart p {
  font-size: 1.2em;
  color: #555;
}

.shop-now-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  font-size: 1.1em;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  text-decoration: none;
}

.shop-now-button:hover {
  background-color: #0056b3;
}

/* General Mobile Styles */
@media (max-width: 768px) {
	.cart-page {
	  margin-top: 80px;
	}
  
	.cart-items {
	  display: flex;
	  flex-direction: column; /* Stack items and total vertically */
	  gap: 20px;
	  margin-bottom: 40px;
	}
  
	.cart-item-card {
	  flex-direction: column; /* Stack items vertically */
	  margin: 20px;
	  height: auto; /* Remove fixed height */
	}
  
	.cart-item-details,
	.cart-item-image, .cart-item-name {
	  flex: 1;
	  text-align: center;
	}

	.cart-item-description {
		display: block; /* Ensure it's treated as a block element */
		white-space: nowrap; /* Prevent text wrapping */
		overflow: hidden; /* Hide overflowing content */
		text-overflow: ellipsis; /* Add '...' at the end */

	}
  
	.cart-item-image img {
	  max-width: 70%; /* Ensure image fits the screen */
	}
  
	.cart-item-quantity {
	  display: flex;
	  flex-direction: row; /* Keep buttons and quantity in a row */
	  align-items: center; /* Align items vertically in the center */
	  gap: 10px; /* Add space between buttons and quantity */
	}
  
	.cart-item-quantity button {
	  background-color: #23297a84; /* Consistent button background */
	  color: #fff; /* White text for contrast */
	  border: none;
	  border-radius: 4px; /* Rounded corners for a modern look */
	  width: 36px; /* Fixed width for consistency */
	  height: 36px; /* Fixed height for consistency */
	  font-size: 1rem; /* Legible font size */
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  cursor: pointer;
	  transition: background-color 0.3s ease; /* Smooth hover effect */
	}
  
	.cart-item-quantity button:hover {
	  background-color: #1e255e; /* Slightly darker hover state */
	}
  
	.cart-item-quantity span {
	  font-size: 1.2rem; /* Make the quantity text slightly larger */
	  font-weight: bold; /* Highlight the quantity value */
	  min-width: 24px; /* Ensure consistent width */
	  text-align: center; /* Center-align the quantity */
	}
  
	.cart-total {
		margin-left: 20px;
		margin-right: 20px;
	}
  
	.empty-cart {
	  width: 100%;
	}
  }
  

:root {
	--hue: 223;
	--bg: hsl(var(--hue),90%,90%);
	--fg: hsl(var(--hue),90%,10%);
	--danger: hsl(3,90%,45%);
	--danger-down: hsl(3,90%,65%);
	--white: hsl(0,0%,100%);
	--trans-dur: 0.3s;
}

.del-btn {
	background-color: var(--danger);
	border-radius: 0.2em;
	color: var(--white);
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: auto;
	outline: transparent;
	padding: 0.75em 1.5em;
	-webkit-tap-highlight-color: #0000;
	transition: background-color var(--trans-dur);
}
.del-btn:not(:disabled):focus-visible,
.del-btn:not(:disabled):hover {
	background-color: var(--danger-down);
}
.del-btn__icon {
	display: block;
	margin: -0.75em 0 -0.75em -0.75em;
	pointer-events: none;
	width: 3em;
	height: 3em;
}
.del-btn__icon-lid {
	transform-origin: 12px 5px;
}
.del-btn__icon-can {
	transform-origin: 12px 18px;
}
.del-btn__letters {
	display: flex;
}
.del-btn__letter,
.del-btn__letter-box {
	display: inline-block;
}
.del-btn[data-running="false"] .del-btn__letters {
	animation: fadeIn 0.3s cubic-bezier(0.77,0,0.18,1) forwards;
}
.del-btn[data-running="true"] .del-btn__icon-lid,
.del-btn[data-running="true"] .del-btn__icon-can,
.del-btn[data-running="true"] .del-btn__icon-can-fill,
.del-btn[data-running="true"] .del-btn__letter,
.del-btn[data-running="true"] .del-btn__letter-box {
	animation: trashLid 2s cubic-bezier(0.77,0,0.18,1) forwards;
}
.del-btn[data-running="true"] .del-btn__icon-can {
	animation-name: trashCan;
}
.del-btn[data-running="true"] .del-btn__icon-can-fill {
	animation-name: trashCanFill;
}
.del-btn[data-running="true"] .del-btn__letter-box {
	animation-name: letterDrop;
	animation-timing-function: cubic-bezier(0.77,0,1,1);
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(1) .del-btn__letter {
	animation-name: letter1;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(2),
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(2) .del-btn__letter {
	animation-delay: 0.04s;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(2) .del-btn__letter {
	animation-name: letter2;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(3),
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(3) .del-btn__letter {
	animation-delay: 0.08s;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(3) .del-btn__letter {
	animation-name: letter3;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(4),
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(4) .del-btn__letter {
	animation-delay: 0.12s;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(4) .del-btn__letter {
	animation-name: letter4;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(5),
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(5) .del-btn__letter {
	animation-delay: 0.16s;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(5) .del-btn__letter {
	animation-name: letter5;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(6),
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(6) .del-btn__letter {
	animation-delay: 0.2s;
}
.del-btn[data-running="true"] .del-btn__letter-box:nth-child(6) .del-btn__letter {
	animation-name: letter6;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,90%);
	}
}

/* Animations */
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes trashLid {
	from,
	86%,
	to {
		transform: translate(0,0) rotate(0);
	}
	33.33%,
	46% {
		animation-timing-function: cubic-bezier(0.77,0,1,1);
		transform: translate(-2px,-4px) rotate(-40deg);
	}
	54%,
	70% {
		animation-timing-function: linear;
		transform: translate(0,0) rotate(0);
	}
	62% {
		animation-timing-function: linear;
		transform: translate(0,-2px) rotate(9deg);
	}
	78% {
		animation-timing-function: cubic-bezier(0,0,0.18,1);
		transform: translate(0,-1px) rotate(-6deg);
	}
}
@keyframes trashCan {
	from,
	86%,
	to {
		transform: translate(0,0) rotate(0);
	}
	33.33%,
	46% {
		animation-timing-function: cubic-bezier(0.77,0,1,1);
		transform: translate(-8px,3px) rotate(40deg);
	}
	54%,
	70% {
		animation-timing-function: linear;
		transform: translate(0,0) rotate(0);
	}
	62% {
		animation-timing-function: linear;
		transform: translate(-1px,0) rotate(-4deg);
	}
	78% {
		animation-timing-function: cubic-bezier(0,0,0.18,1);
		transform: translate(0,0) rotate(2deg);
	}
}
@keyframes trashCanFill {
	from,
	25%,
	to {
		y: 24px;
	}
	66.67%,
	83.33% {
		y: 12px;
	}
}
@keyframes letterDrop {
	from,
	33.33% {
		transform: translate(0,0);
	}
	41.67%,
	to {
		transform: translate(0,0.625em);
	}
}
@keyframes letter1 {
	from {
		transform: translate(0,0) rotate(0);
	}
	33.33% {
		transform: translate(0.75em,0) rotate(30deg) scale(1);
	}
	41.67% {
		transform: translate(-2.375em,0) rotate(-135deg) scale(0.75);
	}
	46%,
	to {
		transform: translate(-2.375em,0) rotate(-135deg) scale(0);
	}
}
@keyframes letter2 {
	from {
		transform: translate(0,0) rotate(0);
	}
	33.33% {
		transform: translate(0.75em,0) rotate(30deg) scale(1);
	}
	41.67% {
		transform: translate(-3em,0) rotate(-135deg) scale(0.75);
	}
	46%,
	to {
		transform: translate(-3em,0) rotate(-135deg) scale(0);
	}
}
@keyframes letter3 {
	from {
		transform: translate(0,0) rotate(0);
	}
	33.33% {
		transform: translate(0.75em,0) rotate(30deg) scale(1);
	}
	41.67% {
		transform: translate(-3.375em,0) rotate(-135deg) scale(0.75);
	}
	46%,
	to {
		transform: translate(-3.375em,0) rotate(-135deg) scale(0);
	}
}
@keyframes letter4 {
	from {
		transform: translate(0,0) rotate(0);
	}
	33.33% {
		transform: translate(0.75em,0) rotate(30deg) scale(1);
	}
	41.67% {
		transform: translate(-3.75em,0) rotate(-135deg) scale(0.75);
	}
	46%,
	to {
		transform: translate(-3.75em,0) rotate(-135deg) scale(0);
	}
}
@keyframes letter5 {
	from {
		transform: translate(0,0) rotate(0);
	}
	33.33% {
		transform: translate(0.75em,0) rotate(30deg) scale(1);
	}
	41.67% {
		transform: translate(-4.125em,0) rotate(-135deg) scale(0.75);
	}
	46%,
	to {
		transform: translate(-4.125em,0) rotate(-135deg) scale(0);
	}
}
@keyframes letter6 {
	from {
		transform: translate(0,0) rotate(0);
	}
	33.33% {
		transform: translate(0.75em,0) rotate(30deg) scale(1);
	}
	41.67% {
		transform: translate(-4.75em,0) rotate(-135deg) scale(0.75);
	}
	46%,
	to {
		transform: translate(-4.75em,0) rotate(-135deg) scale(0);
	}
}


