 
  .details-ccontainer {
    width: 654px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.15);
  }
  
  .product-ddetails {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .image-wwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-iimage {
    border-radius: 7px 0 0 7px;
    width: 327px;
    height: auto;
    margin-bottom: 16px;
    object-fit: cover;
  }
  
  .additional-iimages {
    display: flex;
    gap: 8px;
  }
  
  .additional-iimage {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  .product-iinfo {
    background-color: #ffffff;
    border-radius: 0 7px 10px 7px;
    padding: 20px;
    width: 327px;
  }
  
  .product-ttitle {
    font-family: 'Bentham', serif;
    font-size: 34px;
    color: #474747;
    margin-bottom: 8px;
  }
  
  .product-ddescription {
    font-family: 'Playfair Display', serif;
    font-size: 15px;
    color: #8d8d8d;
    line-height: 1.7em;
    margin-bottom: 16px;
  }
  
  .price-iinfo {
    margin-bottom: 16px;
  }
  
  .product-pprice {
    font-family: 'Suranna', serif;
    font-size: 34px;
    color: #0056b3;
    margin-bottom: 8px;
  }
  
  .was-pprice {
    font-family: 'Trocchi', serif;
    font-size: 28px;
    color: #d2d2d2;
    text-decoration: line-through;
  }
  
  .product-sstock,
  .product-wwarranty {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: #8d8d8d;
    margin-bottom: 8px;
  }
  
  .feature-ttitle {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: #474747;
    margin-bottom: 8px;
  }
  
  .feature-llist {
    list-style: none;
    padding-left: 0;
    margin-bottom: 16px;
    font-size: 14px;
    color: #8d8d8d;
  }
  
  .add-to-cart-bbutton,
  .back-bbutton {
    display: block;
    margin: 8px 0;
    padding: 14px 40px;
    border: none;
    border-radius: 60px;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #ffffff;
    cursor: pointer;
    background-color: #007bff;
    text-align: center;
    box-sizing: border-box;
  }
  
  .add-to-cart-bbutton:hover,
  .back-bbutton:hover {
    background-color: #ffffff;
    color: #007bff;
    border: 1px solid #007bff; /* Add a solid border with color */
  }
  


  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    width: 70%; /* Adjust this to your preferred modal width */
    height: 70%; /* Adjust this to your preferred modal height */
    max-width: 700px; /* Ensure a max width */
    max-height: 600px; /* Ensure a max height */
    text-align: center;
    overflow: hidden; /* Ensures the image stays within the container */
    border-radius: 10px;
    background-color: white; /* Optional, for card-like background */
  }
  
  .large-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the image fits within the container, maintaining its aspect ratio */
    border-radius: 10px;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    color: rgb(61, 3, 3);
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .prev-image,
  .next-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    color: white;
    background: rgba(0, 0, 0, 0.232);
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .prev-image {
    left: 10px;
  }
  
  .next-image {
    right: 10px;
  }
  
  .prev-image:hover,
  .next-image:hover {
    background: rgba(255, 255, 255, 0.8);
    color: black;
  }
  
  

  .cart-alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    z-index: 1000;
  }


  .scene {
    height: 100%;
    position: relative;
    
  }
  
  .cart-container {
    width: 900px;
    margin: 5em auto;
    margin-top: 7em;
  }
  
  #cart {
    width: 150px;
    display:inline-block;
  }
  
  .product {
    width: 60px;
    position: absolute;
    left: 50%;
    opacity: 0;
    transform: scale(2);
  }
  
  #image {
    width: 300px;
    margin: 1em auto;
    position: relative;
    top: -20px;
    img {
      width: 100%;
    }
  }

  
  