/* Main Navbar Styling */
.navbar {
  background-color: #ffffff; /* Main background color */
  color: #fff;
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: transform 0.3s ease;
}

/* Adjustments for existing navbar styles */
.navbar-top {
  background-color: #3F7CAC;
  padding: 10px;
  text-align: center;
  z-index: 1001; /* Keep it above the rest */
}

/* Welcome Message Styling */
.welcome-message {
  color: #333;
  font-size: 20px;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Hides the overflowing text */
  display: inline-block;
  animation: slideLeft 20s linear infinite; /* Infinite sliding animation */
}

/* Animation for sliding left */
@keyframes slideLeft {
  from {
    transform: translateX(100%); /* Start from the right outside the container */
  }
  to {
    transform: translateX(-100%); /* End on the left outside the container */
  }
}

/* Navbar Middle Styling */
.navbar-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #ffffff; /* Match main navbar color */
  color: #3F7CAC;
}

/* Navbar Logo Styling */
.navbar-logo {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.logo-image {
  height: 60px; /* Adjust the logo size */
}

/* Navbar Search Bar Styling */
.navbar-search input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 400px;
}

/* Navbar Icons Styling */
.navbar-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-item {
  text-align: center;
}

.icon {
  color: #3F7CAC;
  font-size: 20px;
  text-decoration: none;
  display: block;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #f1690f;
}

.icon-label {
  display: block;
  color: #3F7CAC;
  font-size: 15px;
  margin-top: 4px;
}

/* Navbar Menu Styling */
.navbar-menu {
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: #ffffff;
  color: #3F7CAC;
  padding: 20px 0;
  margin-left: 155px;
  margin-right: 155px;
  z-index: 999;
  border-top: 1px solid #3f7dac3f;
}

.navbar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
  color: #3F7CAC;
}

.navbar-menu li {
  position: relative;
}

.navbar-menu a {
  color: #3F7CAC;
  text-decoration: none;
  font-size: 17px;
  transition: color 0.3s ease;
}

.navbar-menu a:hover {
  color: #f46c04;
}

/* Dropdown styles */
.navbar .dropdown {
  position: relative;
}

.navbar .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  flex-direction: column;
}

.navbar .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar .dropdown-menu li {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
}

.navbar .dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.navbar .dropdown-menu li ul li {
  position: relative; /* Position relative to handle the pseudo-element */
  overflow: hidden; /* Ensure underline stays within bounds */
}

.navbar .dropdown-menu li ul li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0; /* Start with no underline visible */
  height: 2px; /* Adjust thickness of the underline */
  background-color: #3F7CAC; /* Match your preferred color */
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

.navbar .dropdown-menu li ul li:hover::after {
  width: 100%; /* Expand underline across the full width */
}

.navbar .dropdown-menu li ul li:hover {
  text-decoration: none; /* Disable the default underline */
}

/* Active category background color */
.navbar .dropdown-toggle.active {
  background-color: #f1690f; /* You can change this to any color you want */
  color: #fff; /* Change the text color for better contrast */
}


/* Mobile View Adjustments */
@media (max-width: 768px) {
  /* Align logo, search, cart, and my account in one row without wrapping */
  .navbar-middle {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
  }

  /* Remove wishlist item */
  .navbar-icons .icon-item:nth-child(2) {
    display: none;
  }

  /* Adjust search field to take less space */
  .navbar-search input {
    width: 200px;
  }

  /* Make My Account only show the icon (no text) */
  .navbar-icons .icon-item:last-child .icon-label {
    display: none;
  }

  /* Ensure navbar logo doesn't shrink too much */
  .navbar-logo .logo-image {
    width: 100px; /* Adjusted logo size */
  }

  /* General Navbar Styles */
.navbar-menu {
  display: flex;
  flex-direction: row;  /* Stack categories horizontally */
  align-items: center;
  padding: 0;
  margin: 10px 0;
  list-style-type: none;
}

.navbar-menu li {
  position: relative; /* Needed for dropdown positioning */
  text-align: center;
}

/* Dropdown Menu Styles */
.navbar .dropdown-menu {
  position: absolute;
  top: 100%;  /* Position the dropdown below the parent category */
  left: 0;
  display: none;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for dropdown */

  z-index: 999;  /* Ensure dropdown is above other content */
}

.navbar .dropdown:hover .dropdown-menu {
  display: flex;  /* Show on hover */
}

/* Subcategory List Styling */
.navbar .dropdown-menu li {
  margin: 0;
  font-size: 8px;  /* Smaller font size for subcategories */
  text-align: left;
}

.navbar .dropdown-menu li ul {
  display: inline-block;  /* Display sub-subcategories inline */
  padding-left: 20px;  /* Slight indent for sub-subcategories */
}

/* Styling for active category */
.navbar .dropdown-toggle.active {
  background-color: #f1690f;
  color: #fff;
}

/* Optional: Hover effect for category links */
.navbar-menu li:hover {
  background-color: #f5f5f5; /* Light gray hover effect */
  border-radius: 5px;
  transition: background-color 0.3s ease;
}



  /* Adjust icon sizes */
  .navbar-icons .icon-item .icon {
    font-size: 20px;
  }
}
