.navbar-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-link {
  display: inline-block;
  text-decoration: none;
}

.whatsapp-image {
  width: 50px;
  height: 50px;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Keyframes for bouncing animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
}
