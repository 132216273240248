.product-list-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 160px;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  

  