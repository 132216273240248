/* src/components/Auth.css */
/* Container to center the form */
.centered-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 90vh; /* Full viewport height */
  padding-top: 160px;
}

/* Auth Form Styling */
.auth-form {
  max-width: 600px; /* Increased width to accommodate two columns */
  width: 100%; /* Ensure it takes full width up to max-width */
  padding: 20px; /* Reduced padding for better spacing */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.auth-form h2 {
  margin-bottom: 20px;
}

.auth-form .input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.auth-form input {
  flex: 1;
  margin: 0 5px; /* Space between inputs */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.auth-form input[type="file"] {
  flex: 1;
  margin: 0;
}

.auth-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.auth-form button:hover {
  background-color: #0056b3;
}

.auth-form .error {
  color: red;
  margin: 10px 0;
}


/* src/components/Auth.css */
/* Container to center the login form */
.login-centered-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 90vh; /* Full viewport height */
}

/* Login Form Styling */
.login-form {
  max-width: 400px; /* Width suitable for a single column form */
  width: 100%; /* Ensure it takes full width up to max-width */
  padding: 20px; /* Reduced padding for better spacing */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.login-form h2 {
  margin-bottom: 20px;
}

.login-input-row {
  margin-bottom: 15px;
}

.login-form input {
  width: 100%; /* Full width of the container */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form .error {
  color: red;
  margin: 10px 0;
}

.signup-link {
  margin-top: 20px;
  text-align: center;
}

.signup-link p {
  margin-bottom: 10px;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}


.login-link {
  margin-top: 20px;
  text-align: center;
}

.login-link p {
  margin-bottom: 10px;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

