/* Container for the entire page */
.category-page {
  font-family: Arial, sans-serif;
  margin-left: 20px;
  padding: 40px;
  color: #3F7CAC;
}



/* Header styling */
.category-header {
  text-align: center;
}

.category-header h1 {
  margin: 0;
  font-size: 2em;
  color: #333;
}

/* Error message styling */
.error-message {
  color: red;
  text-align: center;
  font-size: 1.2em;
  margin: 20px 0;
}

/* Container for the product list */
.product-ist {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Space between items */
  padding: 20px 0;
}

/* Product grid styling */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Product card */
.product-itemm {
  flex: 0 0 230px; /* Width of the card */
  height: 340px; /* Set a fixed height for the card */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.5s ease;
  padding: 1px; /* Added padding inside the card */
}

.product-itemm:hover {
  box-shadow: 0 0 15px rgb(28, 49, 143);
}

/* Product image wrapper */
.product-image-wrapper {
  width: 100%;
  height: 65%; /* Image occupies half of the card */

}

.product-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Product details */
.product-details {
  flex-grow: 1; /* Allow the content to grow and fill available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure space between elements */
}

.product-name, .product-pricee, .product-description {
  text-decoration: none; /* Remove underline from product name, price, and description */
  margin: 2px 0;
}

.product-name {
  font-size: 14px;
  font-weight: bold;
  margin: 2px 0;
 
}

.product-price {
  font-size: 15px;
  color: #c82424;
  margin: 2px 0;

}

.product-description {
  font-size: 10px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 2px 0;
}

/* View Details button */
.view-details-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  align-self: center; /* Center the button horizontally */
  margin-top: auto; /* Pushes the button to the bottom */
  display: inline-block;
}

.view-details-button:hover {
  background-color: #fcfcfc;
  border-color: #007bff; /* Specify the border color on hover */
  color: #007bff;
  border-width: 1px; /* Change the border width on hover */
  border-style: solid; /* Ensure the border is visible */
}

/* Ensure no underlines on the product name, price, and description */
.product-item a {
  text-decoration: none;
  color: inherit; /* Ensure the color is inherited */
}

.product-item a:hover {
  text-decoration: none;
}
